import React, { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import axios from 'axios';
import { Box, Button, Drawer, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';

interface QRResult {
  rawValue: string;
}

const GiftCard: React.FC = () => {
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [discountData, setDiscountData] = useState<Record<string, any> | null>(null);

  const fetchCardData = async (id: number) => {
    try {
      const response = await axios.get(`https://franchise.slimstore.hu/api/giftcards.php?id=${id}`);
      setDiscountData(response.data);
    } catch (error) {
      console.error('Hiba a kártya lekérdezése során:', error);
      window.alert('Nem sikerült lekérdezni a kártya adatait.');
    }
  };

  const handleScan = (data: string) => {
    if (data) {
      try {
        const decodedData = decodeDiscountCard(data);
        console.log('Decoded data:', decodedData);
        fetchCardData(decodedData?.id);
        setIsScannerOpen(false);
      } catch (error) {
        console.error('Hiba a QR kód feldolgozása során:', error);
        window.alert("Hibás QR kód formátum");
      }
    }
  };
  const updateCardAmount = async (id: number, amount: number) => {
    try {
      const response = await axios.post(`https://franchise.slimstore.hu/api/giftcards.php?id=${id}&amount=${amount}`);
      window.alert(response.data.message || 'Sikeres feltöltés!');
      fetchCardData(id); // Frissítjük a kártyaadatokat
    } catch (error) {
      console.error('Hiba a kártya feltöltése során:', error);
      window.alert('Nem sikerült feltölteni a kártyát.');
    }
  };
  function decodeDiscountCard(encoded: string): Record<string, any> | null {
    try {
      // Iteratív módon keresünk JSON-kompatibilis részt
      let jsonPart = '';
      for (let i = 0; i < encoded.length; i++) {
        try {
          jsonPart = atob(encoded.substring(0, i).replace(/-/g, '+').replace(/_/g, '/'));
          // Megpróbáljuk JSON-ként értelmezni
          const data = JSON.parse(jsonPart);
          return data; // Ha sikeres, visszaadjuk az adatokat
        } catch {
          // Nem csinálunk semmit, folytatjuk a keresést
        }
      }
      throw new Error('Could not find a valid JSON part in the encoded string.');
    } catch (error) {
      console.error('Failed to decode data:', error);
      return null; // Hiba esetén null-t adunk vissza
    }
  }



  const handleError = (error: Error) => {
    console.error('QR kód hiba:', error);
    window.alert("Hibás QR kód");
  };

  const handleOpenScanner = () => {
    setIsScannerOpen(true);
  };

  const handleCloseModal = () => {
    setResponseMessage('');
    setIsScannerOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
      <Typography variant="h4" gutterBottom>
        Ajándékkártya
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpenScanner}>
        QR kód beolvasása
      </Button>

            <Drawer anchor="bottom" open={isScannerOpen} onClose={() => setIsScannerOpen(false)}>
                <Box p={2}>
                  <Scanner onScan={(result) => handleScan(result[0].rawValue)} onError={(error) => console.error("QR Code error:", error)} />
                </Box>
              </Drawer>

              <Dialog open={!!responseMessage} onClose={handleCloseModal}>
        <DialogContent>
          <Typography>{responseMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {discountData && (
  <Box mt={2}>
    <Typography variant="h6">Kártya adatai</Typography>
    <Typography>ID: {discountData.cardid}</Typography>
    <Typography>Összeg: {discountData.amount || 0}</Typography>
    {discountData.amount !> 0 || discountData != null && (<Box mt={2}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          const amount = parseInt(prompt('Add meg a feltöltendő összeget:') || '0');
          if (amount > 0) updateCardAmount(discountData.cardid, amount);
          else window.alert('Érvénytelen összeg.');
        }}
      >
        Feltöltés
      </Button>
    </Box>)}
  </Box>
)}
    </Box>
  );
};

export default GiftCard;